import "./header.css";




const Header = () => {
  return (
    <header id="header">
      <div className="header__containerpc">
        <h5>This is</h5>
        <h1>
          LA<span className="h">H</span>ON
        </h1>
        <div className="wrapper2">
          <div className="typing-demo">an engineer who loves Space</div>
        </div>
        
      </div>
    </header>
  );
};

export default Header;
